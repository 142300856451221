import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import eventBus from '@/utils/eventBus';
import axios from "axios";
import { VideoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
export default {
  components: {
    VideoPlayer: VideoPlayer
  },
  data: function data() {
    return {
      artices: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        //播放速度
        autoplay: true,
        // 如果true,浏览器准备好时开始回放。
        muted: false,
        // 默认情况下将会消除任何音频。
        loop: true,
        // 导致视频一结束就重新开始。
        preload: 'auto',
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false,
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: [require('../assets/images/test.mp4'), require('../assets/images/test2.mp4')][Math.round(Math.random())]
        }],
        controls: true,
        hls: true,
        poster: '',
        // 你的封面地址
        width: 200,
        // 播放器宽度
        height: 200,
        //notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮

        }
      }
    };
  },
  created: function created() {
    var _this = this;

    this.init();
    eventBus.$on('selectLanguage', function () {
      _this.init();
    });
  },
  methods: {
    init: function init() {
      var _this2 = this;

      var lang = localStorage.getItem("lang");
      var url = "/defi/site/artice/serve";
      axios.get(url, {
        headers: {
          "X-Lang": lang
        }
      }).then(function (res) {
        res.data.data.artices.map(function (r) {
          r.content = _this2.escape2Html(r.content);
          r.show = false;
        });
        _this2.artices = res.data.data.artices;
      });
    },
    escape2Html: function escape2Html(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t];
      });
    }
  },
  computed: {
    global: {
      get: function get() {
        return this.$store.state.global;
      }
    },
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    }
  }
};